/**
 * Watches window visibility and triggers mutations based on
 * state changes.
 */

export default function visibilityPlugin(store) {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === undefined) {
      return; // Do nothing if not supported
    }
    const visible = document.visibilityState === 'visible';
    const mutation = visible ? 'SET_VISIBLE' : 'SET_HIDDEN';

    store.commit(`browser/${mutation}`);
  });
}
