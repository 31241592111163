export default {
  state: {
    showStreamDialog: false,
    showViewStreamDialog: false,
    currentStream: null,
    expandBanner: null,
    isStreaming: null,
    isViewing: null,
    mediaStream: null,
    nativeVideoSettings: null,
  },

  getters: {
    expandBanner: (state) => {
      if (state.expandBanner === null) {
        const expandBanner = window.sessionStorage.getItem('expandBanner');
        return Boolean(
          expandBanner === null ? true : parseInt(expandBanner, 10),
        );
      }
      return state.expandBanner;
    },
  },

  mutations: {
    OPEN_VIEW_STREAM_DIALOG: (state, stream) => {
      state.showViewStreamDialog = true;
      state.currentStream = stream;
    },
    CLOSE_VIEW_STREAM_DIALOG: (state) => {
      state.showViewStreamDialog = false;
      state.currentStream = null;
    },
    OPEN_STREAM_DIALOG: (state) => { state.showStreamDialog = true; },
    CLOSE_STREAM_DIALOG: (state) => { state.showStreamDialog = false; },
    SET_CURRENT_STREAM: (state, stream) => { state.currentStream = stream; },
    EXPAND_LIVE_NOW_BANNER: (state) => {
      sessionStorage.setItem('expandBanner', 1);
      state.expandBanner = true;
    },
    COLLAPSE_LIVE_NOW_BANNER: (state) => {
      sessionStorage.setItem('expandBanner', 0);
      state.expandBanner = false;
    },
    SET_STREAMING_TRUE: (state) => {
      state.isStreaming = true;
    },
    SET_STREAMING_FALSE: (state) => {
      state.isStreaming = false;
    },
    SET_VIEWING_TRUE: (state) => {
      state.isViewing = true;
    },
    SET_VIEWING_FALSE: (state) => {
      state.isViewing = false;
      state.currentStream = null;
    },

    /* Sets the MediaStream instance */
    SET_MEDIA_STREAM: (state, mediaStream) => {
      state.mediaStream = mediaStream;
    },

    SET_NATIVE_VIDEO_SETTINGS: (state, settings) => {
      state.nativeVideoSettings = settings;
    },
  },
};
