/* Orientation Plugin
 * Watches the browser window for orientation change events.
 */
import { debounce } from 'lodash-es';

import {
  ORIENTATION_LANDSCAPE,
  ORIENTATION_PORTRAIT,
} from 'main/store/modules/browser';

export default function orientationPlugin(store) {
  function commitOrientationState() {
    const isLandscape = window.innerWidth >= window.innerHeight;
    const orientation = isLandscape
      ? ORIENTATION_LANDSCAPE
      : ORIENTATION_PORTRAIT;

    if (store.getters['browser/orientation'] === orientation) {
      return;
    }

    let commitType;
    if (orientation === ORIENTATION_LANDSCAPE) {
      commitType = 'SET_ORIENTATION_LANDSCAPE';
    } else {
      commitType = 'SET_ORIENTATION_PORTRAIT';
    }
    store.commit(`browser/${commitType}`);
  }

  commitOrientationState(); // Execute on load

  window.addEventListener('orientationchange', () => {
    // Introduce slight delay because updated window dimensions not
    // immediately available.
    setTimeout(commitOrientationState, 100);
  }, {
    capture: true,
    passive: true,
  });

  window.addEventListener('resize', debounce(commitOrientationState), {
    capture: true,
    passive: true,
  });
}
