import UAParser from 'ua-parser-js';

export const SET_HIDDEN = 'SET_HIDDEN';
export const SET_ONLINE = 'SET_ONLINE';
export const SET_OFFLINE = 'SET_OFFLINE';
export const SET_SERVICE_WORKER_REGISTRATION = (
  'SET_SERVICE_WORKER_REGISTRATION'
);
export const SET_VISIBLE = 'SET_VISIBLE';
export const SET_USER_AGENT = 'SET_USER_AGENT';
export const LOCK_SCROLLING = 'LOCK_SCROLLING';
export const UNLOCK_SCROLLING = 'UNLOCK_SCROLLING';

export default () => ({
  namespaced: true,

  actions: {
    setOnline({ commit }) {
      commit(SET_ONLINE);
    },

    setOffline({ commit }) {
      commit(SET_OFFLINE);
    },

    lockScrolling({ commit }) {
      commit(LOCK_SCROLLING);
    },

    unlockScrolling({ commit }) {
      commit(UNLOCK_SCROLLING);
    },
  },

  state: {
    isOnline: navigator.onLine,
    lastVisible: new Date(),
    scrollingLocked: false,
    serviceWorkerRegistration: null,
    uaAgent: window.navigator.userAgent,
  },

  getters: {
    isVisible: (state) => state.isVisible,
    isMobile: (state, getters) => getters.uaData.device.type === 'mobile',
    isOnline: (state) => state.isOnline,
    lastVisible: (state) => state.lastVisible,
    scrollingLocked: (state) => state.scrollingLocked,
    serviceWorkerRegistration: (state) => state.serviceWorkerRegistration,
    uaData: (state) => new UAParser(state.uaAgent).getResult(),
  },

  mutations: {
    [SET_HIDDEN]: (state) => {
      state.isVisible = false;
      state.lastVisible = new Date();
    },
    [SET_ONLINE]: (state) => { state.isOnline = true; },
    [SET_OFFLINE]: (state) => { state.isOnline = false; },
    [SET_SERVICE_WORKER_REGISTRATION]: (state, serviceWorkerRegistration) => {
      state.serviceWorkerRegistration = serviceWorkerRegistration;
    },
    [SET_USER_AGENT]: (state, value) => { state.uaAgent = value; },
    [SET_VISIBLE]: (state) => { state.isVisible = true; },
    [LOCK_SCROLLING]: (state) => { state.scrollingLocked = true; },
    [UNLOCK_SCROLLING]: (state) => { state.scrollingLocked = false; },
  },
});
