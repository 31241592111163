import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class UserAdminSettings extends Model {
  static fields() {
    return {
      id: this.number(null),
      banned_from_streaming: this.boolean(false),
    };
  }

  async banStreaming() {
    await axios.post(`/api/users/${this.id}/ban_streaming/`);
    this.banned_from_streaming = true;
    this.$save();
  }

  async unbanStreaming() {
    await axios.delete(`/api/users/${this.id}/ban_streaming/`);
    this.banned_from_streaming = false;
    this.$save();
  }
}

UserAdminSettings.entity = 'userAdminSettings';
