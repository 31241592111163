import axios from 'axios';

import User from 'main/models/User';

const FOLLOWEE_CACHE_TIME = 3600; // 1 hour

export default {
  state: {
    followeeIds: [], // IDs of users being followed by current user
    user_id: null, // ID for current user,
    joinNowBannerClosed: false,
  },

  mutations: {
    setFollowees(state, followeeIds) {
      state.followeeIds = followeeIds;
    },

    setJoinNowBannerClosed(state, closed) {
      state.joinNowBannerClosed = closed;
    },
  },

  getters: {
    currentUser(state) {
      if (!state.user_id) {
        return new User();
      }
      const user = User.find(state.user_id);
      return user;
    },

    isFollowing: (state) => (id) => state.followeeIds.includes(id),

    allFolloweeIds: (state) => state.followeeIds,

    joinNowBannerClosed: (state) => state.joinNowBannerClosed,
  },

  actions: {
    async loadFollowees({ commit, state }) {
      if (!state.user_id) {
        return commit('setFollowees', []);
      }

      const cacheKey = 'followees';
      const cached = localStorage.getItem(cacheKey);
      let needsRefresh = false;
      let followeeIds = [];

      if (cached !== null) {
        const cacheRecord = JSON.parse(cached);
        const now = Math.floor(new Date().getTime() / 1000);
        const elapsed = now - cacheRecord.refreshed;

        if (elapsed > FOLLOWEE_CACHE_TIME) {
          needsRefresh = true;
        } else {
          ({ followeeIds } = cacheRecord);
        }
      } else {
        needsRefresh = true;
      }

      if (needsRefresh) {
        const url = `/api/users/${state.user_id}/followees/`;
        const response = await axios.get(url);
        followeeIds = response.data.map((followee) => followee.followee_id);

        const cacheRecord = {
          refreshed: Math.floor(new Date().getTime() / 1000),
          followeeIds,
        };

        localStorage.setItem(cacheKey, JSON.stringify(cacheRecord));
      }

      return commit('setFollowees', followeeIds);
    },
  },
};
