<template>
  <div
    v-if="streaming"
    class="timer"
  >
    {{ timer }}
  </div>
</template>

<script>
import { addSeconds, intervalToDuration } from 'date-fns';

function formatElapsedTime(elapsedSeconds) {
  const start = new Date(0);
  const end = addSeconds(start, elapsedSeconds);
  const { hours, minutes, seconds } = intervalToDuration({ start, end });
  const fragments = [minutes, seconds];
  if (hours) {
    fragments.splice(0, 0, hours);
  }
  return fragments.map((f) => f.toString().padStart(2, 0)).join(':');
}

export default {
  props: {
    /**
     * Start time in seconds
     */
    start: {
      type: Number,
      required: false,
      default: null,
    },
    /**
     * Last tick time in seconds
     */
    lastTick: {
      type: Number,
      required: false,
      default: null,
    },
    streaming: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  computed: {
    timer() {
      const elapsedSeconds = this.lastTick && this.start
        ? this.lastTick - this.start : 0;
      return formatElapsedTime(elapsedSeconds);
    },
  },
};
</script>
