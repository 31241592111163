import { Model } from '@vuex-orm/core';

export default class User extends Model {
  static fields() {
    return {
      id: this.number(null),
      url: this.string(''),
      username: this.string(''),
      email: this.string(''),
      full_name: this.string(''),
      first_name: this.string(''),
      last_name: this.string(''),
      avatar: this.string(''),
      is_authenticated: this.boolean(false),
      is_staff: this.boolean(false),
      date_joined: this.string(null),
      user_type: this.string('member'),
    };
  }
}

User.entity = 'users';
