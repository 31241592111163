/**
 * Listens for events to refresh live stream data.
 */
import Stream from 'main/models/Stream';

export default function liveRefreshPlugin(store) {
  store.subscribe(async (mutation) => {
    let refresh = false;

    if (mutation.type === 'browser/SET_ONLINE') {
      refresh = true;
    } else if (mutation.type === 'browser/SET_VISIBLE') {
      // Refresh if hidden longer than 60 seconds
      refresh = (new Date() - store.getters['browser/lastVisible']) > 60 * 1000;
    }

    if (refresh) {
      await Stream.dispatch('refresh');
    }
  });
}
