/**
 * Stores browser, layout related states.
 *
 * Extends `common/store/browser`
 */
import { merge } from 'lodash-es';

import browser from 'common/store/browser';

export const ORIENTATION_LANDSCAPE = 'landscape';
export const ORIENTATION_PORTRAIT = 'portrait';

const common = browser();

export default merge(
  common, {
    state: {
      csrfCookieName: null,
      isSmallScreen: null,
      orientation: null,
      supportsWebRtc: (
        window.navigator
        && window.navigator.mediaDevices
        && !!window.fetch // Check made from stream-module lib
        && typeof Object.assign === 'function' // From stream-module
        && window.RTCPeerConnection !== undefined
        && window.RTCPeerConnection !== null
      ),
    },

    getters: {
      csrfCookieName: (state) => state.csrfCookieName,
      isSmallScreen: (state) => state.isSmallScreen,
      orientation: (state) => state.orientation,
    },

    mutations: {
      SET_SMALL_SCREEN_TRUE: (state) => { state.isSmallScreen = true; },
      SET_SMALL_SCREEN_FALSE: (state) => { state.isSmallScreen = false; },
      SET_ORIENTATION_LANDSCAPE: (state) => {
        state.orientation = ORIENTATION_LANDSCAPE;
      },
      SET_ORIENTATION_PORTRAIT: (state) => {
        state.orientation = ORIENTATION_PORTRAIT;
      },
    },
  },
);
