// eslint-disable-next-line max-len
// Documentation: https://retentionscience.zendesk.com/hc/en-us/articles/360001874554-User-interaction-events-101
import Vue from 'vue';

export default {
  /**
   * Initialize Retention Science tracker.
   *
   * The initialization will send a "page view" event automatically.
   * There is no specific code required for page views.
   */
  init(user) {
    if (!RESCI_SITE_ID) {
      Vue.$log.warn(
        'Skipped Resci init because the `RESCI_SITE_ID` env var has not '
        + 'been set.',
      );
      return;
    }
    window._rsq = window._rsq || [];

    window._rsq.push(['_setSiteId', RESCI_SITE_ID]);
    window._rsq.push(['_enableOnsite']);

    if (user && user.is_authenticated) {
      window._rsq.push(['_setUserId', user.id]);
      window._rsq.push(['_setUserEmail', user.email]);
    }
    this._track();

    const rScix = document.createElement('script');
    rScix.setAttribute('type', 'text/javascript');
    rScix.setAttribute(
      'src',
      'https://d1stxfv94hrhia.cloudfront.net/waves/v3/w.js',
    );
    rScix.setAttribute('async', '');
    const head = document.querySelector('head');
    head.appendChild(rScix);
  },

  /**
   * Issue the XHR call.
   *
   * The `_track` command executes the request and sends the last
   * event that was pushed to `_rsq`. It is common to all events.
   */
  _track() {
    window._rsq.push(['_track']);
  },
};
