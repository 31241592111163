import axios from 'axios';

export default {
  state: {
    balance: null, // Current balance available for tipping
    fundingBonus: null,
  },

  mutations: {
    setBalance(state, balance) {
      state.balance = balance;
    },
    setFundingBonus(state, fundingBonus) {
      state.fundingBonus = fundingBonus;
    },
  },

  getters: {
    balance: (state) => state.balance,
    fundingBonus: (state) => state.fundingBonus,

    /**
     * Return the user's current balance formatted for display.
     */
    formattedBalance(state) {
      if (state.balance === null) {
        return '--.--';
      }

      return state.balance.toFixed(2);
    },
  },

  actions: {
    /**
     * Refresh the user's current balance from the server.
     */
    async refreshBalance({ commit }) {
      const url = '/api/balance/';
      const response = await axios.get(url);
      if (response.status === 200) {
        commit('setBalance', response.data.amount);
      }
    },

    /**
     * Send the tip request to the server.
     */
    async tip({ commit }, { payload, url }) {
      try {
        const response = await axios.post(url, payload);
        commit('setBalance', response.data.balance);
        return { amount: payload.amount };
      } catch (e) {
        const error = new Error('Could not send tip');
        error.response = e;
        throw error;
      }
    },
  },
};
