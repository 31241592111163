import axios from 'axios';
import { sortBy, uniqBy } from 'lodash-es';

import Stream from 'main/models/Stream';

const PRIVACY_FOLLOWERS = 2;

export default {
  getters: {
    allUniqueByUser: (state, getters) => {
      let streams = getters.query()
        .with('user')
        .orderBy('last_active', 'desc')
        .get();

      streams = uniqBy(streams, (stream) => stream.user.username);
      streams = sortBy(streams, (stream) => stream.user.username);

      return streams;
    },

    getStreamByUserId: (state, getters) => (userId) => (
      getters.query()
        .with('user')
        .where('user_id', userId)
        .orderBy('last_active', 'desc')
        .first()
    ),
  },

  actions: {
    async refresh({ dispatch }) {
      const response = await axios.get('/api/live/streams/');
      const data = response.data.results;
      await dispatch('deleteAll');
      await dispatch('insert', { data });
    },

    receiveStreamData({ rootGetters }, data) {
      if (data.privacy === PRIVACY_FOLLOWERS) {
        const followeeIds = rootGetters.allFolloweeIds;
        if (!followeeIds.includes(data.user_id)) {
          return;
        }
      }
      Stream.insertOrUpdate({ data });
    },

    async streamStartedEvent({ dispatch }) {
      await dispatch('refresh');
    },

    async streamEndedEvent(context, stream) {
      await Stream.delete((record) => record.room_name === stream.room_name);
    },
  },
};
