import Cookies from 'js-cookie';
import Vue from 'vue';

import PusherJS from 'contrib/pusher-js';
import { Stream } from 'main/models/';

const SET_CLIENT = 'SET_CLIENT';
const SET_LIVE_CHANNEL = 'SET_LIVE_CHANNEL';
const SET_PRIVATE_CHANNEL = 'SET_PRIVATE_CHANNEL';

/**
 * Pusher Vuex module
 */
export default {
  namespaced: true,

  state: {
    client: null,
    liveChannel: null,
    privateChannel: undefined,
    authURL: null, // URL for authenticating Pusher Websockets
  },

  actions: {
    /**
     * Instantiates a PusherJS client and if the user is authenticated,
     * subscribes to their private channel
     */
    init({ commit, getters, rootGetters }, { user, Pusher = PusherJS }) {
      if (!PUSHER_CHANNELS_KEY || !PUSHER_CHANNELS_CLUSTER) {
        Vue.$log.error(
          'Could not init Pusher. Please set the following environment '
          + 'variables with valid credentials: PUSHER_CHANNELS_APP_ID, '
          + 'PUSHER_CHANNELS_KEY, PUSHER_CHANNELS_SECRET and '
          + 'PUSHER_CHANNELS_CLUSTER',
        );
        return;
      }
      const authEndpoint = getters.authURL;
      const csrfCookieName = rootGetters['browser/csrfCookieName'];

      const client = new Pusher(PUSHER_CHANNELS_KEY, {
        authEndpoint,
        cluster: PUSHER_CHANNELS_CLUSTER,
        auth: { headers: { 'X-CSRFToken': Cookies.get(csrfCookieName) } },
      });

      commit(SET_CLIENT, client);

      if (user.is_authenticated) {
        const channel = client.subscribe(`private-${user.id}`);
        commit(SET_PRIVATE_CHANNEL, channel);
      }

      const liveChannel = client.subscribe('live');
      commit(SET_LIVE_CHANNEL, client.subscribe('live'));

      liveChannel.bind(
        'stream_started',
        (data) => Stream.dispatch('streamStartedEvent', data),
      );
      liveChannel.bind(
        'stream_ended',
        (data) => Stream.dispatch('streamEndedEvent', data),
      );
    },

    disconnect({ commit, getters }) {
      const { client } = getters;

      if (!client) {
        return;
      }

      client.disconnect();

      commit(SET_CLIENT, null);
      commit(SET_LIVE_CHANNEL, null);
      commit(SET_PRIVATE_CHANNEL, null);
    },
  },

  getters: {
    client: (state) => state.client,
    liveChannel: (state) => state.liveChannel,
    privateChannel: (state) => state.privateChannel,
    authURL: (state) => state.authURL,
  },

  mutations: {
    [SET_LIVE_CHANNEL]: (state, channel) => {
      state.liveChannel = channel;
    },

    [SET_PRIVATE_CHANNEL]: (state, channel) => {
      state.privateChannel = channel;
    },

    [SET_CLIENT]: (state, client) => {
      state.client = client;
    },
  },
};
