/* Resize Plugin
 * Watches the browser window for resize events.
 */
import { debounce } from 'lodash-es';

const breakpoint = 600;

export default function resizePlugin(store) {
  function commitMobileState() {
    const isSmallScreen = window.innerWidth < breakpoint;
    if (store.getters['browser/isSmallScreen'] === isSmallScreen) {
      return;
    }
    const commitType = isSmallScreen
      ? 'browser/SET_SMALL_SCREEN_TRUE' : 'browser/SET_SMALL_SCREEN_FALSE';
    store.commit(commitType);
  }

  commitMobileState(); // Execute on load

  window.addEventListener('resize', debounce(commitMobileState), {
    capture: true,
    passive: true,
  });
}
