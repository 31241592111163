import Pusher from 'pusher-js';

/* Include cross-domain credentials when pusher authenticates to
 * `api` subdomain.
 *
 * Context:
 *   - https://github.com/pusher/pusher-js/pull/509
 *   - https://github.com/pusher/pusher-js/issues/99
 *   - https://github.com/pusher/pusher-js/issues/62
 */
Pusher.Runtime.createXHR = () => {
  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  return xhr;
};

export default Pusher;
