import VuexORM from '@vuex-orm/core';
import { get, merge } from 'lodash-es';

import resci from 'common/resci';
// Models
import { Stream, User, UserAdminSettings } from 'main/models';
// Modules
import browser from 'main/store/modules/browser';
import live from 'main/store/modules/live';
import pusher from 'main/store/modules/pusher';
import session from 'main/store/modules/session';
import streams from 'main/store/modules/streams';
import tipping from 'main/store/modules/tipping';
import userAdminSettings from 'main/store/modules/userAdminSettings';
import users from 'main/store/modules/users';
// Plugins
import liveRefreshPlugin from 'main/store/plugins/liveRefreshPlugin';
import onlinePlugin from 'main/store/plugins/onlinePlugin';
import orientationPlugin from 'main/store/plugins/orientationPlugin';
import persistedStatePlugin from 'main/store/plugins/persistedState';
import pusherConnectionPlugin from 'main/store/plugins/pusherConnectionPlugin';
import resizePlugin from 'main/store/plugins/resizePlugin';
import visibilityPlugin from 'main/store/plugins/visibilityPlugin';

// Load VuexORM
const database = new VuexORM.Database();
database.register(Stream, streams);
database.register(User, users);
database.register(UserAdminSettings, userAdminSettings);

const storeConfig = {
  plugins: [
    VuexORM.install(database),
    liveRefreshPlugin,
    onlinePlugin,
    orientationPlugin,
    persistedStatePlugin,
    pusherConnectionPlugin,
    resizePlugin,
    visibilityPlugin,
  ],
  modules: {
    browser,
    live,
    pusher,
    session,
    tipping,
  },
};

/**
 * Merges a provided object representing state with
 * a Vuex config. Recurses into modules.
 */
export function mergeState({ config, state }) {
  // Loops over modules and merges state properties
  Object.keys(config.modules || {}).forEach((key) => {
    config.modules[key].state = merge(
      config.modules[key].state,
      get(state, key),
    );
  });

  // Merges global state properties
  config.state = merge(config.state, state);

  return config;
}

/**
 * createStore()
 *
 * Creates a store instance using the storeConfig as a default
 * and the state provided in the __INITIAL_STATE__ window property
 * rendered by in the Django template.
 *
 * The values set in the template take precedence over the
 * state defined in the store configuration and its modules.
 */
function createStore({
  Vuex,
  state = window.__INITIAL_STATE__, // From Django HTML template
  config = storeConfig,
} = {}) {
  const mergedConfig = mergeState({ config, state });
  const store = new Vuex.Store(mergedConfig);
  const entities = window.__INITIAL_ENTITIES__ || [];

  const classMapping = {
    Stream,
    User,
    UserAdminSettings,
  };

  const promises = [];
  entities.forEach((entity) => {
    Object.keys(entity).forEach((key) => {
      if (!(key in classMapping)) {
        return;
      }

      promises.push(classMapping[key].insert({ data: entity[key] }));
    });
  });
  Promise.all(promises).then(() => resci.init(store.getters.currentUser));

  return store;
}

export default createStore;
export { storeConfig };
