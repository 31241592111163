import createPersistedState from 'vuex-persistedstate';

export default function persistedStatePlugin(store) {
  return createPersistedState({
    paths: [
      'session.joinNowBannerClosed',
    ],
    storage: window.sessionStorage,
  })(store);
}
