import { Model } from '@vuex-orm/core';

export default class Stream extends Model {
  constructor(object) {
    super(object);
    // Save user_id on create
    if (!this.user_id && object && object.user) {
      this.user_id = object.user.id;
    }
  }

  static fields() {
    return {
      id: this.number(),
      connect_url: this.string(null),
      location: this.attr(''),
      room_name: this.attr(''),
      last_active: this.string(),
      ping_url: this.string(null),
      privacy: this.number(0),
      save_video: this.boolean(true),
      start_url: this.string(null),
      tippable: this.boolean(false),
      tips_url: this.string(null),
      user_id: this.number(null),
      user: this.hasOne('users', 'id', 'user_id'),
    };
  }
}

Stream.entity = 'streams';
