const MAX_HIDDEN_TIMEOUT = 60000;

class PusherConnectionManager {
  constructor(store) {
    this.store = store;

    store.watch(() => store.getters.currentUser, async (user) => {
      const client = store.getters['pusher/client'];

      if (user && user.is_authenticated && !client) {
        await store.dispatch('pusher/init', {
          user: store.getters.currentUser,
        });
      } else if ((!user || !user.is_authenticated) && client) {
        await store.dispatch('pusher/disconnect');
      }
    });

    store.subscribe(async (mutation) => {
      // Disconnect Pusher if window has been hidden for a while,
      // and reconnect it when visible again.
      // It's OK to miss these updates, since we refresh from the REST API
      // anyway as soon as the window becomes visible again.
      const { currentUser } = store.getters;
      const client = store.getters['pusher/client'];
      let timeout = null;

      if (
        mutation.type === 'browser/SET_HIDDEN'
        && currentUser
        && currentUser.is_authenticated
        && client
      ) {
        timeout = setTimeout(async () => {
          if (!store.getters['browser/isVisible']) {
            await store.dispatch('pusher/disconnect');
          }
        }, MAX_HIDDEN_TIMEOUT);
      } else if (mutation.type === 'browser/SET_VISIBLE') {
        clearTimeout(timeout);

        if (currentUser && currentUser.is_authenticated && !client) {
          await store.dispatch('pusher/init', { user: currentUser });
        }
      }
    });

    window.addEventListener('beforeunload', async () => {
      await store.dispatch('pusher/disconnect');
    });
  }
}

export default (store) => new PusherConnectionManager(store);
